<template>
  <div>
    <!-- <svg
      style="position: absolute; width: 100vw"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
    >
      <path
        fill="#a2d9ff"
        fill-opacity="1"
        d="M0,288L34.3,245.3C68.6,203,137,117,206,112C274.3,107,343,181,411,208C480,235,549,213,617,176C685.7,139,754,85,823,53.3C891.4,21,960,11,1029,10.7C1097.1,11,1166,21,1234,58.7C1302.9,96,1371,160,1406,192L1440,224L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"
      ></path>
    </svg> -->

    <div style="height:100vh;background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,104,121,1) 35%, rgba(0,212,255,1) 100%);"  
class="d-flex justify-content-center align-items-center shadow-lg ">
        <div style="width:100%;" class="p-3 ">
          <div class="row justify-content-center animate__animated animate__fadeIn ">
          <!-- <div class="col-sm-6">
                <div class="shadow rounded-lg p-3 text-white row justify-content-center" style="height:90vh;">
                  <div class="col-6">
                      <img 
                      class="animate__animated animate__fadeIn"
                      src="<?php print site_url()."/assets/photo/vektor/Calendar_SVG.svg" ?>" 
                      style="height:200px;display:block;margin:auto;" alt="">
                      <hr>
                      <p class="text-lg text-center capitalize font-semibold">
                          Mobile Harp Attendance
                      </p>
                  </div>
                </div>
            </div> -->
            <div class="col-md-5">
                <div class="text-center  row justify-content-center bg-white p-4 shadow rounded-lg animate__animated animate__fadeInRight" style="min-height:70vh;">
                    <div class="col-sm-12">
                        <!-- style="font-family: 'Rubik Puddles', cursive;" -->
                        <p class="my-3 text-center font-semibold text-xl" >Dr Aesthetic Apps</p>
                        <hr class="my-1">
                        <p class="my-3 text-center text-sm">Copyright 2022</p>
                        <p class="my-3 text-center text-sm">Dr Aesthetic All rights reserved.</p>
                    </div>
                    <div class="col-12"></div>
                    <div class="col-sm-9">
                        <p class="text-lg font-semibold text-lg">Welcome di API Dashboard Dr Aesthetic App!</p>
                        <hr>
                        <form action="" @submit.prevent="login">
                          <div class="sm-form my-2">
                            <input  type="text" id="username" name="username" class="font-light form-control form-control-sm shadow rounded-lg p-3" placeholder="Username" v-model="vdata.username" >
                          </div>
                          <div class="sm-form my-2">
                              <input  type="password" id="password" name="password" class="font-light form-control form-control-sm shadow rounded-lg p-3" placeholder="Password" v-model="vdata.password" >
                          </div>
                          <button type="submit" class="btn btn-sm btn-style4 my-2 italic rounded-lg" style="width:70%;"> Login <span class="typcn typcn-key"></span></button>
                          <hr class="my-2">
                          <p class="text-xs text-center">Version 0.8</p>
                      </form>
                        <!-- <button type="button" @click="test" class="btn btn-sm btn-dark  ">test</button> -->
                    </div>
                </div>
            </div>
            
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import autophp from "@/plugins/_autophp.js"; // ganti source nya
let sdb = new autophp();
export default {
  data() {
    return {
      vdata: {},
      toko: "",
    };
  },
  mounted() {
    sdb
      .collection("app_kasir_toko", false)
      .doc()
      .select(`select * from app_kasir_toko`)
      .then((res) => {
        console.log("kasir ", res);
        this.toko = res[0];
      });
  },
  methods: {
    test(){
      let fd=new FormData()
      fd.append('token-u','admin')
      fd.append('token-p','admin')
      axios.post('https://apis.draesthetic.id/auth/login.php',fd).then(res=>{
        console.log(res)
      })
    },
    login() {
      sdb
        .collection("tbuser")
        .login2(this.vdata, this.$store, this.$router, "/admin")
        .then((res) => {
          localStorage.setItem('now',new Date().getTime()+(4*60*60*1000));
          console.log(res);
        })
        .catch((e) => {
          sdb.alert('Login Gagal');
          console.log(e);
        });
    },
  },
    metaInfo: {
      title: 'Login Dr Aesthetic',
      link: [
       { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Rubik+Puddles&display=swap' },
      ],
  },
};
</script>